import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function Navbar() {
	return (
		<header className="header">
			<div className="logo">
				<Typography variant="h4">Flavorfeed</Typography>
			</div>
			<nav>
				<ul className="navigation">
					<li className="nav-item">
						<Link to="become-a-partner">Become a partner</Link>
					</li>
					<li className="nav-item login">
						<Link className="login-link" to="/login">
							Login
						</Link>
					</li>

					<li className="nav-item signup">
						<Link className="signup-link" to="/signup">
							Sign up
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}

// #ffb703, #fb8500

export default Navbar;
