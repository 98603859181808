import React, { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import { Whisper, IconButton, Badge, Stack, Dropdown, Popover } from "rsuite";
import { Avatar } from "@mui/material";
import { logout, selectUser } from "../../features/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { IoPerson } from "react-icons/io5";
import { IoMdHeart } from "react-icons/io";
import { PiSignOutBold } from "react-icons/pi";
import { FaGear } from "react-icons/fa6";

type NavItemProps = {
	isPending: boolean;
	isActive: boolean;
};

// menu manager or menu
// insights - how many clicks each dish got, customer reviews

const RenderAdminSpeaker = (
	{ onClose, left, top, className }: any,
	ref: any
) => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector(selectUser);

	const handleSelect = (eventKey: any) => {
		console.log(eventKey);

		if (eventKey === "logout") {
			console.log("logout");
			dispatch(logout());
		}

		onClose();
	};

	return (
		<Popover ref={ref} className={className} style={{ left, top }} full>
			<Dropdown.Menu onSelect={handleSelect}>
				<Dropdown.Item panel style={{ padding: 10, width: 180 }}>
					<p>Signed in as</p>
					<strong>{user?.username}</strong>
				</Dropdown.Item>
				<Dropdown.Separator />
				<Dropdown.Item
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
						padding: 0,
					}}
				>
					<Link className="user-dd-item profile" to="/profile">
						<IoPerson size={17} />
						Profile
					</Link>
				</Dropdown.Item>
				<Dropdown.Item
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
						padding: 0,
					}}
				>
					<Link className="user-dd-item favorites" to="/favorites">
						<IoMdHeart size={17} />
						My favorites
					</Link>
				</Dropdown.Item>
				<Dropdown.Separator />
				<Dropdown.Item
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
						width: "100%",
						padding: 0,
					}}
				>
					<Link className="user-dd-item settings" to="/settings">
						<FaGear size={17} />
						Settings
					</Link>
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="logout"
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
					}}
				>
					<PiSignOutBold size={17} />
					Sign out
				</Dropdown.Item>
				{/* <Dropdown.Item
					icon={<FaGear />}
					href="https://rsuitejs.com"
					target="_blank"
					as="a"
				>
					Help{" "}
				</Dropdown.Item> */}
			</Dropdown.Menu>
		</Popover>
	);
};

function UserNavbar() {
	function navLinkClassname({ isActive, isPending }: NavItemProps): string {
		return isPending ? "pending" : isActive ? "active" : "";
	}

	return (
		<Stack className="user-header" spacing={8} justifyContent="space-between">
			<Link className="logo" to="/">
				<Typography variant="h5">Flavorfeed</Typography>
			</Link>

			<Stack>
				<nav className="navigation">
					<NavLink className={navLinkClassname} to="/">
						Home
					</NavLink>
					<NavLink to="/explore" className={navLinkClassname}>
						Explore
					</NavLink>
				</nav>
			</Stack>

			<Stack className="user-dd" spacing={8}>
				{/* <Whisper
					placement="bottomEnd"
					trigger="click"
					// ref={trigger}
					speaker={RenderAdminSpeaker}
				>
					<IoMdNotificationsOutline size={21} />
					<IconButton
						icon={
							<Badge content={5}>
								<IoMdNotificationsOutline />
							</Badge>
						}
					/>
				</Whisper> */}

				<Whisper
					placement="bottomEnd"
					trigger="click"
					// ref={trigger}
					speaker={RenderAdminSpeaker}
				>
					<Avatar
						sx={{ width: 28, height: 28, padding: 2 }}
						style={{ cursor: "pointer" }}
					>
						JL
					</Avatar>
					{/* <Avatar
						size="sm"
						circle
						// src="https://avatars.githubusercontent.com/u/1203827"
						alt="@simonguo"
						style={{ marginLeft: 8 }}
					/> */}
				</Whisper>
			</Stack>
		</Stack>
	);
}

export default UserNavbar;
